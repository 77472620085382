<template>
  <div>
    <div class="pdfok" id="pdfok" v-if="pdfok">
    <div id="printMe" style=" width: 740px; margin: 0 auto;">
      <div class="A4Container ">
        <div class="" style="padding: 0!important;">
          <div class="table" style="">

            <div class=""
                    style="height: 50px; width: 100%; font-weight: 700;text-align: center;">{{config.schoolname}}耗材入库单
            </div>


            <el-row class="flexStretch borderR ">
              <el-col :span="4" class="cell flexCenter" style="">申请人</el-col>
              <el-col :span="4" class="cell flexCenter">{{DataInfo.username||'-'}}</el-col>
              <el-col :span="3" class="cell flexCenter" style="">所在部门</el-col>
              <el-col :span="4" class="cell flexCenter" style="padding: 0 3px;">{{DataInfo.dept_name||'-'}}</el-col>
              <el-col :span="3" class="cell flexCenter" style="">申请时间</el-col>
              <el-col :span="6" class="cell flexCenter">{{DataInfo.cdate||'-'}}</el-col>
            </el-row>
            <el-row v-if="DataInfo.sg_num" class="flexStretch borderR ">
              <el-col :span="4" class="cell flexCenter" style="">入库单号</el-col>
              <el-col :span="20" class="cell flexCenter">{{DataInfo.rk_num}}</el-col>
            </el-row>

            <el-row class="flexStretch borderR ">
              <el-col :span="4" class="cell flexCenter" style="">入库总价(元)</el-col>
              <el-col :span="20" class="cell flexCenter">
                <span>￥{{DataInfo.howmuch_rk||'0'}}</span>&nbsp;|&nbsp;<span>{{DataInfo.howmuch_rk?upperCaseMoney(DataInfo.howmuch_rk):upperCaseMoney(0)}}</span>
              </el-col>
            </el-row>

            <el-row class="flexStretch borderR ">
              <el-col :span="4" class="cell flexCenter" style="">入库仓库</el-col>
              <el-col :span="20" class="cell flexCenter">{{DataInfo.storename}}</el-col>
            </el-row>

            <el-row class="flexStretch borderR borderB">
              <el-col :span="4" class="cell flexCenter" style="">仓库管理员</el-col>
              <el-col :span="20" class="cell flexCenter">{{DataInfo.store_admin_name}}</el-col>
            </el-row>
          </div>
        </div>

        <div class="" style="padding: 0!important;margin-top: 20px;">
          <div class="table" style="">
            <el-row class="flexStretch borderR borderB" style="">
              <el-col :span="4" class="cell flexCenter" style="">
                <div v-if="DataInfo.sg_num" style="font-weight: 700;">申购单入库<br/>耗材列表</div>
                <div v-if="!DataInfo.sg_num" style="font-weight: 700;">新增入库<br/>耗材列表</div>
              </el-col>
              <el-col :span="20" class="" style="">
                <el-row>
                  <el-col :span="2" class="cell flexCenter">序号</el-col>
                  <el-col :span="10" class="cell flexCenter" style="">名称</el-col>
                  <el-col :span="6" class="cell flexCenter">入库数量</el-col>
                  <el-col :span="6" class="cell flexCenter">入库金额(元)</el-col>
                </el-row>

                <el-row v-for="(item, index) in DataInfo.hc_list" class="flexStretch">
                  <el-col :span="2" class="cell flexCenter">{{ index+1 }}</el-col>
                  <el-col :span="10" class="cell flexCenter" style="padding: 0 3px;">
                    {{ item.title }}
                  </el-col>
                  <el-col :span="6" class="cell flexCenter">{{ item.rkNum }}{{ item.jldw || '' }}</el-col>
                  <el-col :span="6" class="cell flexCenter">{{ item.rkJine }}</el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="" style="padding: 0!important;margin-top: 20px;">
          <div class="table" style="">
            <el-row class="flexStretch borderR borderB" style="">
              <el-col :span="4" class="cell flexCenter" style="">
                <div style="font-weight: 700;">入库审批</div>
              </el-col>
              <el-col :span="20" class="" style="">
                <el-row class="flexStretch">
                  <el-col :span="4" class="cell flexCenter" style="">入库审批人</el-col>
                  <el-col :span="20" class="cell flexCenter">{{ DataInfo.sp_status>0?DataInfo.store_admin_name:'' }}</el-col>
                </el-row>
                <el-row class="flexStretch">
                  <el-col :span="4" class="cell flexCenter" style="">入库意见</el-col>
                  <el-col :span="20" class="cell flexCenter breakWord" style="padding: 0 3px;">
                    {{ DataInfo.sp_status == 1?'【已入库】':'' }}
                    {{ DataInfo.sp_status == 2?'【驳回】':'' }}
                    {{ DataInfo.sp_remark }}
                  </el-col>
                </el-row>
                <el-row class="flexStretch">
                  <el-col :span="4" class="cell flexCenter" style="">审批人签名</el-col>
                  <el-col :span="20" class="cell flexCenter">
                    <div class="" style="font-size: 16px;margin-left: 10px;">
                      <img v-if="DataInfo.ck_handsign" :src="DataInfo.ck_handsign" alt="" class="flexCenter"
                           style="max-height: 40px;max-width: 120px">
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>

        <div v-if="DataInfo.sp_status==3" style="padding: 0!important;margin-top: 20px;">
          <div class="table" style="">
            <el-row class="flexStretch borderR borderB" style="">
              <el-col :span="4" class="cell flexCenter" style="">
                <div style="font-weight: 700;">取消入库</div>
              </el-col>
              <el-col :span="20" class="" style="">
                {{ DataInfo.store_admin_name }} 在 {{ DataInfo.sp_date }} 取消入库
              </el-col>
            </el-row>
          </div>
        </div>

      </div>
    </div>


    <el-button v-if="false" v-print="'#printMe'" type="primary" style="position: fixed;top: 0;left: 0;z-index: 999;">
      打印
    </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'export',
  components: {},
  data() {
    return {
      printObj: {
        id: 'printMe',
        popTitle: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      pdfok: false,
      pdfok1: false,

      DataInfo: {},
      config:{},
    }
  },
  props:['printId'],
  mounted() {
    if(this.printId){
      this.getDataList(this.printId)
      this.getConfig()
    }else {
      let id = this.getParams('id')
      let pmid = this.getParams('pmid')
      this.getDataList(id, pmid)
      this.getConfig(pmid)
    }
  },
  methods: {
    getDataList(id, pmid) {
      let _this = this
      this.$http.post('/api/sx_hc_rk_from', {
        id: id,
        pmid: pmid
      }).then(res => {
        if (res && res.data) {
          let resData = res.data
          resData.listLen = 0
          if (resData.hc_list) {
            resData.hc_list = JSON.parse(resData.hc_list)
            resData.listLen = resData.hc_list.length
            resData.desc = `${resData.username}申请入库${resData.listLen}件物品`
          }
          let files = JSON.parse(resData.files)
          for (let item of files) {
            item.fileName = item.fileName.substr(item.fileName.lastIndexOf('/') + 1)
          }
          resData.files = files;
          this.$nextTick(() => {
            _this.DataInfo = resData
            setTimeout(() => {
              _this.pdfok = true
              console.log("强制刷新")
              _this.$forceUpdate()
            }, 800)
          })
        }

      })

    },

    getParams(name) {
      let n_url = window.location.href
      n_url = n_url.replace('/#', '')
      if (n_url.indexOf('?') > 0) {
        n_url = n_url.split('?')[1]
      }
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = n_url.match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    getConfig(pmid) {
      let _this = this
      let config = {}
      this.$http.post("/api/sys_dictionary_list",{pmid:pmid?pmid:''}).then(res => {
        if (res.data && res.data.length > 0) {
          let len = res.data.length
          for(let i=0;i<len;i++){
            let a = res.data[i]
            if (a.remark.indexOf('app基础配置') > -1 && a.key_values) {
              let json_Arr = JSON.parse(a.key_values)
              if(json_Arr&&json_Arr.length>0){
                let len1 = json_Arr.length
                for (let j=0;j<len1;j++) {
                  let item = json_Arr[j]
                  config[item.field] = item.value
                }
                this.$nextTick(() => {
                  this.config = config
                  _this.pdfok1 = true
                  console.log("强制刷新")
                  _this.$forceUpdate()
                })
              }
              return
            }
          }
        }
      })
    },
    upperCaseMoney(money) {
      // 汉字的数字
      var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
      //基本单位
      var cnIntRadice = new Array('', '拾', '佰', '仟');
      //对应整数部分扩展单位
      var cnIntUnits = new Array('', '万', '亿', '兆');
      //对应⼩数部分单位
      var cnDecUnits = new Array('⾓', '分', '毫', '厘');
      //整数⾦额时后⾯跟的字符
      var cnInteger = '整';
      //整型完以后的单位
      var cnIntLast = '元';
      //最⼤处理的数字
      var maxNum = 999999999999999.9999;
      //⾦额整数部分
      var integerNum;
      //⾦额⼩数部分
      var decimalNum;
      //输出的中⽂⾦额字符串
      var chineseStr = '';
      //分离⾦额后⽤的数组，预定义
      var parts;
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      if (money == '') {
        return '';
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最⼤处理数字
        return '';
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = '';
      } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }

      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == '0') {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //⼩数部分
      if (decimalNum != '') {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }


      }
      if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == '') {
        chineseStr += cnInteger;
      }
      return chineseStr;


    },
    //分割数组 arr：原数组  num：分割后每个数组里的数据条数
    util_spliceArrByN(arr, num) {
      var _arr = []
      while (arr.length) {
        _arr.push(arr.splice(0, num))
      }
      return _arr
    },
    //数组按某属性分组
    groupBy(array, f) {
      let groups = {}
      array.forEach(function (o) {
        let group = JSON.stringify(f(o))
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return Object.keys(groups).map(function (group) {
        return groups[group]
      })
    }
  }
}
</script>

<style lang="less" type="text/less" scoped>

#printMe {
  font-size: 14px;
  color: #303133;
  box-sizing: border-box;
  //border: 1px solid red;
  div {
    box-sizing: border-box;
  }
}

.form_label {
  font-size: 16px;
  white-space: nowrap;
}

.form_input {
  margin-left: 10px;
  flex: 1;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #303133;
}


.A4Container {
  width: 740px;
  //height: 1000px;
  margin: auto;
  page-break-after: always;
  //border: 1px solid red;
  padding: 20px 40px 20px;


  .title {
    font-size: 20px;
    line-height: 50px;
  }


  .content {
    width: 100%;
    height: 100%;
    padding: 10px;
    white-space: break-spaces;
    word-break: break-all;
    overflow: hidden;
  }


  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;

    min-height: 30px;
    //line-height: 30px;
    //text-align: center;
    white-space: nowrap;
    font-size: 14px;
    color: #303133;
    word-break: break-all;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
  }
}

</style>
